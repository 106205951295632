.header1-text {
  font-size: 25px;
  color: #90cdf9;
  font-weight: 500;
}

.header2-text {
  font-size: 14px;
  font-weight: 500;
}

.header3-text {
  font-size: 12px;
  font-weight: 500;
}

.base-text {
  font-size: 12px;
}

.help-text {
  font-size: 12px;
  color: #999;
}

.disabled-text {
  font-size: 12px;
  color: #ccc;
}

.scheduler {
  margin: 20px auto;
  border-spacing: 0;
}

.scheduler td {
  padding: 0px;
}

.expander-space {
  overflow: hidden;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.9em;
  vertical-align: middle;
  margin-top: -1%;
}

.resource-view {
  border: 1px solid #e9e9e9;
  overflow: hidden;
}

.scheduler-view {
  border: 1px solid #e9e9e9;
  margin: 0 0 0 -1px;
  padding: 0;
  position: relative;
}

.scheduler-content {
  position: relative;
  z-index: 2;
}

#scheduler-indicator {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #ff6634;
  z-index: 3;
  bottom: 0;
}

#scheduler-indicator-dot {
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  transform: translate(-3px, 0);
  background-color: #ff6634;
}

.scheduler-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

table.resource-table,
table.scheduler-bg-table,
table.scheduler-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  text-align: center;
}

table.scheduler-table {
  border: 1px solid #e9e9e9;
}

table.scheduler-content-table {
  margin: 0;
  padding: 0;
  border: 0 solid #e9e9e9;
  border-spacing: 0;
}

table.resource-table tr,
table.scheduler-bg-table tr,
table.scheduler-table tr {
  border-bottom: 1px solid #e9e9e9;
}

table.resource-table th,
table.scheduler-table th,
table.resource-table td,
table.scheduler-bg-table td,
table.scheduler-table td {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

table.scheduler-bg-table th {
  border-right: 1px solid #e9e9e9;
}

table.resource-table tr th:last-child, /*table.scheduler-bg-table tr th:last-child, */table.scheduler-table tr th:last-child,
table.resource-table tr td:last-child, /*table.scheduler-bg-table tr td:last-child, */table.scheduler-table tr td:last-child {
  border-right: 0;
}
/*table.resource-table tr:last-child td, table.scheduler-bg-table tr:last-child td, */
table.scheduler-table tr:last-child td {
  border-bottom: 0;
}

.timeline-event {
  position: absolute;
}

.day-event {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.day-event-container {
  text-align: left;
  padding: 5px 5px 0 5px;
}

.round-all {
  border-radius: 4px;
}

.round-head {
  border-radius: 4px 0px 0px 4px;
}

.round-tail {
  border-radius: 0px 4px 4px 0px;
}

.round-none {
  border-radius: 0px;
}

.event-container {
  position: relative;
}

.event-item {
  margin: 1px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  padding-right: 20px !important;
}

.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px !important;
}

.status-dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.ant-radio-button-wrapper-checked {
  background-color: #108ee9;
  color: #ffffff;
}

.icon-nav:hover {
  color: #1e90ff !important;
  box-shadow: 0 0 0px !important;
  cursor: pointer;
}

.add-more-popover-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.popover-calendar {
  width: 300px;
}

.popover-calendar .ant-calendar {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0) !important;
}

.event-resizer {
  position: absolute;
  z-index: 4;
  display: block;
  width: 7px;
  top: -1px;
  bottom: -1px;
}

.event-start-resizer {
  cursor: w-resize;
  left: -1px;
}

.event-end-resizer {
  cursor: e-resize;
  right: -1px;
}

.selected-area {
  position: absolute;
}
.header2-text {
  width: 200px;
}
.header2-text-label {
  cursor: pointer;
  width: calc(100% - 44px);
  position: absolute;
  text-align: center;
  overflow-wrap: break-word;
}

.anticon-left {
  float: left;
  line-height: 24px !important;
}
.anticon-right {
  float: right;
  line-height: 24px !important;
}
.popover-window-id {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-weight: 600;
}
